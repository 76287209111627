import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query aphasiaQuery {
      img0: file(relativePath: { eq: "aphasia_banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0cn: file(relativePath: { eq: "aphasia_banner_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0en: file(relativePath: { eq: "aphasia_banner_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "adult_therapy_PICA.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "adult_therapy3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.Aphasia" description="seo.desc.Aphasia" />
      {locale === "zh-hk" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0.childImageSharp.fluid}
        />
      )}
      {locale === "zh-cn" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0cn.childImageSharp.fluid}
        />
      )}
      {locale === "en" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0en.childImageSharp.fluid}
        />
      )}
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.Aphasia.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col sm={8} className="pb-3">
	    { locale !== "en" && (
              <div className="text-dark">
                <div className="pb-3">
                  {tt("pages.Aphasia.desc1")}
                  <Link to="/therapy#adult">{tt("pages.Aphasia.link1")}</Link>
                  {tt("pages.Aphasia.desc2")}
                </div>
                <div className="pb-3">
                  <ul>
                    <li>{tt("pages.Aphasia.list1")}</li>
                    <li>{tt("pages.Aphasia.list2")}</li>
                    <li>{tt("pages.Aphasia.list3")}</li>
                    <li>{tt("pages.Aphasia.list4")}</li>
                    <li>{tt("pages.Aphasia.list5")}</li>
                  </ul>
                </div>
              </div>
	    )}
	    { locale === "en" && (
              <div className="text-dark">
                <div className="pb-3">
                  {tt("pages.Aphasia.desc1")}
                </div>
	      </div>
	    )}
            </Col>
            <Col className="pb-3">
              <Img
                className="rounded mx-auto d-block border border-warning pb-3"
                fluid={data.img1.childImageSharp.fluid}
              />
            </Col>
          </Row>
          <Row className="pt-4">
	  { locale !== "en" && (
            <Col sm={8} className="pb-3">
              <div className="title-color pb-3">{tt("therapy")}</div>
              <div className="text-dark">{tt("pages.Aphasia.handling")}</div>
            </Col>
	  )}
	  { locale === "en" && (
            <Col sm={8} className="pb-3">
              <div className="text-dark"><Link to="/nsp">NSP</Link>{tt("pages.Aphasia.handling")}</div>
            </Col>
	  )}
            <Col>
              <Img
                className="rounded mx-auto d-block pb-3"
                fluid={data.img2.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
